import { ReactElement } from "react";
import BoxButton from "../../components/BoxButton";
import Header from "../../components/Header";
import SubTitle from "../../components/SubTitle";
import Title from "../../components/Title";
import styles from "./Error404.module.scss";

const Error404 = (): ReactElement => (
  <div id="error404" className={styles.container}>
    <Header />
    <section className={styles.principalSection}>
      <Title text="If it doesn´t exist in the metaverse," />
      <SubTitle text="Let’s build it together." />
      <BoxButton />
    </section>
  </div>
);

export default Error404;
