import { ReactElement } from "react";
import BoxButton from "../../components/BoxButton";
import Header from "../../components/Header";
import SubTitle from "../../components/SubTitle";
import Title from "../../components/Title";
import down_arrow from "../../assets/images/Home/down_arrow.png";
import styles from "./Home.module.scss";

const Home = (): ReactElement => (
  <div id="home" className={styles.container}>
    <Header />
    <section className={styles.principalSection}>
      <Title
        text="Your Virtual Space, Reimagined."
        customClassName={styles.titleMobile}
      />
      <SubTitle
        customClassName={styles.subTitleMobile}
        text="Custom 3D buildings & experiences in the
      Metaverse made by professional architects."
      />
      <BoxButton />
    </section>
    <div className={styles.arrowContainer}>
      <img src={down_arrow} alt="down arrow" />
    </div>
  </div>
);

export default Home;
