import { ReactElement } from "react";
import styles from "./Title.module.scss";
import Parser from "html-react-parser";

interface ITitle {
  text: string;
  customClassName?: string;
}

const Title = ({ text, customClassName }: ITitle): ReactElement => {
  const titleStyles = `${styles.title} ${customClassName || ''}`;
  return <h1 className={titleStyles}>{Parser(text)}</h1>;
};

export default Title;
