import { ReactElement } from "react";
import { HashLink } from "react-router-hash-link";
import { ButtonThemeTypes } from "../Button/Button";
import styles from "../Button/Button.module.scss";

export interface IButtonHash {
  text: string;
  theme: ButtonThemeTypes;
  customClass?: string;
  to?: string;
}

const ButtonHash = ({
  text,
  theme,
  to = "#",
  customClass,
}: IButtonHash): ReactElement => {
  return (
    <HashLink
      key={to}
      smooth
      to={to}
      className={`${styles.button} ${styles[theme]} ${
        customClass && customClass
      }`}
    >
      {text}
    </HashLink>
  );
};

export default ButtonHash;
