import { ReactElement } from "react";
import styles from "./Paragraph.module.scss";
import Parser from "html-react-parser";

export interface IParagraph {
  text: string;
  theme: ParagraphThemeTypes;
}

export enum ParagraphThemeTypes {
  ABOUT_PRIMARY = "paragraphAboutPrimary",
  ABOUT_SECONDARY = "paragraphAboutSecondary",
  METAVERSE_PRIMARY = "paragraphMetaversePrimary",
  METAVERSE_SECONDARY = "paragraphMetaverseSecondary",
}

const Paragraph = ({ text, theme }: IParagraph): ReactElement => {
  return (
    <p className={`${styles.paragraph} ${styles[theme]}`}>{Parser(text)}</p>
  );
};

export default Paragraph;
