import Title from "../../components/SectionTitle";
import styles from "./Metaverses.module.scss";
import MetaverseTypes from "./components/MetaverseTypes";
import Paragraph from "../../components/Paragraph";
import { ParagraphThemeTypes } from "../../components/Paragraph/Paragraph";
import ProjectsCount from "./components/ProjectsCount";

const Metaverses = () => {
  return (
    <div id="metaverse" className={styles.metaverse}>
      <Title whitecolor text="Metaverses" />
      <div className={styles.metaverseBody}>
        <div className={styles.metaverseDescription}>
          <ProjectsCount total={100} />
          <Paragraph
            theme={ParagraphThemeTypes.METAVERSE_PRIMARY}
            text="Founded in 2020, Voxel Architects aims to create immersive virtual experiences by developing original buildings and unique structures ranging from diverse architectural styles and artistic currents."
          />
        </div>
        <div className={styles.metaverseTypes}>
          <Paragraph
            theme={ParagraphThemeTypes.METAVERSE_SECONDARY}
            text="See our most recent projects on the different Metaverses."
          />
          <MetaverseTypes />
        </div>
      </div>
    </div>
  );
};

export default Metaverses;
