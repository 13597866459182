export const servicesData = [
  {
    id: 1,
    title: "Architecture and Design",
    description:
      "The foundation of any successful product is a superb design. Your vision and ideas can come to life thanks to the expertise of our talented architects which will guarantee that your construction will look great and produce an enduringly memorable experience.",
  },
  {
    id: 2,
    title: "3D Modelling",
    description:
      "From initial sketch, planning, and design, we provide further construction of the buildings to other virtual worlds, including Decentraland, Cryptovoxels, The Sandbox, Mona and Somnium Space, creating a unique identity and approach for each Metaverse",
  },
  {
    id: 3,
    title: "Development",
    description:
      "Web3 development, Metaverse SDKs, and API integrations. We can implement any service or feature the client needs, like payment systems, asset interactions, live video streaming, or chat interfaces and deployment. A simple build can be transformed with code.",
  },
];
