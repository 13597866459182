import styles from "./Partners.module.scss";
import { dataPartners } from "./Partners.data";
import Title from "../../components/SectionTitle";
import BoxImage from "../../components/BoxImage";

const Partners = () => {
  return (
    <div id="partners" className={styles.partner}>
      <Title text={"Partners & Clients"} />

      <div className={styles.partnerImages}>
        {dataPartners.map((partner) => {
          return (
            <BoxImage
              key={partner.id}
              id={partner.id}
              name={partner.name}
              imageUrl={partner.image}
              width={partner.width}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Partners;
