import styles from "./Paragraph.module.scss";

const Paragraph = () => (
  <div className={styles.paragraph}>
    <h1>Let’s build the Metaverse together</h1>
    <h4>
      You can also write to us at{" "}
      <a href="mailto:info@voxelarchitects.com">info@voxelarchitects.com</a>
    </h4>
    <div className={styles.greenLine}></div>
  </div>
);

export default Paragraph;
