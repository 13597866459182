import decentraland_logo from "../../../../assets/images/Metaverses/Decentraland.png";
import sandBox_logo from "../../../../assets/images/Metaverses/SandBox.png";
import somniumSpace_logo from "../../../../assets/images/Metaverses/Somnium_Space.png";
import mona_logo from "../../../../assets/images/Metaverses/Mona.png";
import cryptoVoxels_logo from "../../../../assets/images/Metaverses/Crypto_Voxels.png";

interface IMetaversesData {
  id: string;
  name: string;
  logo: string;
  width?: string;
}

export const dataMetaverses: IMetaversesData[] = [
  {
    id: "1",
    name: "Decentraland",
    logo: decentraland_logo,
  },
  {
    id: "2",
    name: "SandBox",
    logo: sandBox_logo,
  },
  {
    id: "3",
    name: "SomniumSpace",
    logo: somniumSpace_logo,
  },
  {
    id: "4",
    name: "CryptoVoxels",
    logo: cryptoVoxels_logo,
  },
  {
    id: "5",
    name: "Mona",
    logo: mona_logo,
    width: "60%",
  },
];
