import { ReactElement } from "react";
import { NavLink } from "react-router-dom";
import styles from "./Button.module.scss";

export interface IButtonLink {
  text: string;
  theme: ButtonThemeTypes;
  customClass?: string;
  to?: string;
}

export enum ButtonThemeTypes {
  BORDERED_WHITE = "borderedWhite",
  FILLED_BLACK = "filledBlack",
}

const ButtonLink = ({
  text,
  theme,
  to = "#",
  customClass,
}: IButtonLink): ReactElement => {
  return (
    <NavLink
      to={{ pathname: to }}
      target="_blank"
      className={`${styles.button} ${styles[theme]} ${
        customClass && customClass
      }`}
    >
      <span>{text}</span>
    </NavLink>
  );
};

export default ButtonLink;
