import twitter_icon from "../../assets/images/SocialMedia/social_twitter.png";
import instagram_icon from "../../assets/images/SocialMedia/social_instagram.png";
import medium_icon from "../../assets/images/SocialMedia/social_medium.png";
import linkedin_icon from "../../assets/images/SocialMedia/social_linkedin.png";
import discord_icon from "../../assets/images/SocialMedia/social_discord.png";
import metaverse_icon from "../../assets/images/SocialMedia/social_metaverse.png";
import crypto_icon from "../../assets/images/SocialMedia/social_cryptovoxel.png";
import decentraland_icon from "../../assets/images/SocialMedia/social_decentraland.png";
import twitter_hover_icon from "../../assets/images/SocialMedia/social_twitter_hover.png";
import instagram_hover_icon from "../../assets/images/SocialMedia/social_instagram_hover.png";
import medium_hover_icon from "../../assets/images/SocialMedia/social_medium_hover.png";
import linkedin_hover_icon from "../../assets/images/SocialMedia/social_linkedin_hover.png";
import discord_hover_icon from "../../assets/images/SocialMedia/social_discord_hover.png";
import metaverse_hover_icon from "../../assets/images/SocialMedia/social_metaverse_hover.png";
import crypto_hover_icon from "../../assets/images/SocialMedia/social_cryptovoxel_hover.png";
import decentraland_hover_icon from "../../assets/images/SocialMedia/social_decentraland_hover.png";
import {
  SOCIAL_TWITTER,
  SOCIAL_LINKEDIN,
  SOCIAL_INSTAGRAM,
  SOCIAL_MEDIUM,
  SOCIAL_DISCORD,
  SOCIAL_DECENTRALAND,
  SOCIAL_METAVERSE,
  SOCIAL_CRYPTOVOXELS,
} from "../../constants/socialLinks";

interface ISocialMediaData {
  id: string;
  name: string;
  icon: string;
  icon_hover: string;
  url: string;
  order: number;
  state: boolean;
}

export const dataSocial: ISocialMediaData[] = [
  {
    id: "1",
    name: "Twitter",
    icon: twitter_icon,
    icon_hover: twitter_hover_icon,
    url: SOCIAL_TWITTER,
    order: 1,
    state: true,
  },
  {
    id: "2",
    name: "Instagram",
    icon: instagram_icon,
    icon_hover: instagram_hover_icon,
    url: SOCIAL_INSTAGRAM,
    order: 2,
    state: true,
  },
  {
    id: "3",
    name: "Linkedin",
    icon: linkedin_icon,
    icon_hover: linkedin_hover_icon,
    url: SOCIAL_LINKEDIN,
    order: 3,
    state: true,
  },
  {
    id: "4",
    name: "Medium",
    icon: medium_icon,
    icon_hover: medium_hover_icon,
    url: SOCIAL_MEDIUM,
    order: 4,
    state: true,
  },
  {
    id: "5",
    name: "Discord",
    icon: discord_icon,
    icon_hover: discord_hover_icon,
    url: SOCIAL_DISCORD,
    order: 5,
    state: false,
  },
  {
    id: "6",
    name: "Metaverse",
    icon: metaverse_icon,
    icon_hover: metaverse_hover_icon,
    url: SOCIAL_METAVERSE,
    order: 6,
    state: false,
  },
  {
    id: "7",
    name: "CryptoVoxel",
    icon: crypto_icon,
    icon_hover: crypto_hover_icon,
    url: SOCIAL_CRYPTOVOXELS,
    order: 7,
    state: false,
  },
  {
    id: "8",
    name: "Discord",
    icon: decentraland_icon,
    icon_hover: decentraland_hover_icon,
    url: SOCIAL_DECENTRALAND,
    order: 8,
    state: false,
  },
];
