import { ReactElement, useState } from "react";
import styles from "./BackToTop.module.scss";

const BackToTop = (): ReactElement => {
  const [verticalPosition, setVerticalPosition] = useState(0);

  window.onscroll = function (e) {
    setVerticalPosition(window.pageYOffset);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={styles.backToTop}>
      <p
        onClick={scrollToTop}
        className={verticalPosition > 600 ? styles.show : ""}
      >
        TO TOP →
      </p>
    </div>
  );
};

export default BackToTop;
