import metamask from "../../assets/images/Partners/metamask.svg";
import galaxy from "../../assets/images/Partners/galaxy_interactive.svg";
import animoca from "../../assets/images/Partners/animoca_brands.svg";
import consensys from "../../assets/images/Partners/consensys.svg";
import sandbox from "../../assets/images/Partners/the_sandbox.svg";
import decentraland from "../../assets/images/Partners/decentraland.svg";
import cryptoVoxels from "../../assets/images/Partners/crypto_voxels.svg";
import mona from "../../assets/images/Partners/mona.svg";
import somnium from "../../assets/images/Partners/somnium_space.svg";
import tomSachs from "../../assets/images/Partners/tom_sachs.svg";
import stroer from "../../assets/images/Partners/stroer_precision_x.svg";
import auroboros from "../../assets/images/Partners/auroboros.svg";
import sothebys from "../../assets/images/Partners/sothebys.svg";
import olivex from "../../assets/images/Partners/olivex.svg";
import whale from "../../assets/images/Partners/whale.svg";
import landWorks from "../../assets/images/Partners/landworks.svg";
import E1337 from "../../assets/images/Partners/e1337.svg";
import metapurse from "../../assets/images/Partners/metapurse.svg";
import realVision from "../../assets/images/Partners/real_vision.svg";
import minniemuse from "../../assets/images/Partners/minniemuse.svg";
import notfungible from "../../assets/images/Partners/not_fungible.svg";
import metaResidence from "../../assets/images/Partners/meta_residence.svg";
import nftstudios from "../../assets/images/Partners/nftstudios.png";
import raregotchi from "../../assets/images/Partners/raregotchi.svg";
import cryptoMotors from "../../assets/images/Partners/crypto_motors.svg";

interface IPartnersData {
  id: string;
  name: string;
  image: string;
  width?: string;
  order: number;
}

export const dataPartners: IPartnersData[] = [
  {
    id: "1",
    name: "Metamask",
    image: metamask,
    order: 1,
  },
  {
    id: "2",
    name: "Galaxy",
    image: galaxy,
    width: "35%",
    order: 2,
  },
  {
    id: "3",
    name: "Animoca Brands",
    image: animoca,
    width: "35%",
    order: 3,
  },
  {
    id: "4",
    name: "Consensys",
    image: consensys,
    order: 4,
  },
  {
    id: "5",
    name: "The Sandbox",
    image: sandbox,
    order: 5,
  },
  {
    id: "6",
    name: "Decentraland",
    image: decentraland,
    order: 6,
  },
  {
    id: "7",
    name: "Crypto Voxels",
    image: cryptoVoxels,
    order: 7,
  },
  {
    id: "8",
    name: "Mona",
    image: mona,
    order: 8,
  },
  {
    id: "9",
    name: "Somnium Space",
    image: somnium,
    order: 9,
  },
  {
    id: "10",
    name: "Tom Sachs",
    image: tomSachs,
    order: 10,
  },
  {
    id: "11",
    name: "Stroer Precision X",
    image: stroer,
    order: 11,
  },
  {
    id: "12",
    name: "Auroboros",
    image: auroboros,
    order: 12,
  },
  {
    id: "13",
    name: "Sothebys",
    image: sothebys,
    order: 13,
  },
  {
    id: "14",
    name: "Olivex",
    image: olivex,
    order: 14,
  },
  {
    id: "15",
    name: "Whale",
    image: whale,
    width: "25%",
    order: 15,
  },
  {
    id: "16",
    name: "LandWorks",
    image: landWorks,
    order: 16,
  },
  {
    id: "17",
    name: "E1337",
    image: E1337,
    order: 17,
  },
  {
    id: "18",
    name: "Metapurse",
    image: metapurse,
    order: 18,
  },
  {
    id: "19",
    name: "Real Vision",
    image: realVision,
    order: 19,
  },
  {
    id: "20",
    name: "Meta Residence",
    image: metaResidence,
    width: "25%",
    order: 20,
  },
  {
    id: "21",
    name: "Minniemuse",
    image: minniemuse,
    width: "40%",
    order: 21,
  },
  {
    id: "22",
    name: "Not Fungible",
    image: notfungible,
    order: 22,
  },
  {
    id: "23",
    name: "NFTStudios",
    image: nftstudios,
    order: 23,
    width: "60%",
  },
  {
    id: "24",
    name: "Raregotchi",
    image: raregotchi,
    order: 24,
  },
  {
    id: "25",
    name: "CryptoMotors",
    image: cryptoMotors,
    order: 25,
  },
];
