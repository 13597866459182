import { ReactElement } from "react";
import styles from "./BoxImage.module.scss";

interface IBoxImage {
  id: string;
  name: string;
  imageUrl: string;
  width?: string;
  customClass?: string;
}

const BoxImage = ({
  id,
  name,
  imageUrl,
  width,
  customClass,
}: IBoxImage): ReactElement => (
  <div key={id} className={`${styles.boxImage} ${customClass && customClass}`}>
    <img src={imageUrl} alt={name} width={width ?? "50%"} />
  </div>
);

export default BoxImage;
