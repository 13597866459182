import { ReactElement } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Main from "../../pages/Main";
import Error404 from "../../pages/Error404";
import ScrollToTop from "../ScrollToTop";
import { HOME } from "../../constants/routes";
import BackToTop from "../BackToTop";
import EntryLoader from "../EntryLoader";
import loading from "../../assets/images/Home/loading.gif";

const App = (): ReactElement => (
  <EntryLoader time={4000} img={loading} alt="Voxel Architects loading...">
    <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path={HOME} component={Main} />
            <Route component={Error404} />
          </Switch>
        </ScrollToTop>
        <BackToTop />
    </Router>
  </EntryLoader>
);

export default App;
