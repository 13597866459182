import { ReactElement } from "react";
import styles from './Card.module.scss';

interface ICard {
   title: string;
   textContent: string;
}

const Card = ({ title, textContent }: ICard): ReactElement => {
  return (
    <div className={styles.card}>
      <div className={styles.cardContentContainer}>
        <h2 className={styles.cardTitle}>{title}</h2>
        <p className={styles.cardContent}>{textContent}</p>
      </div>
    </div>
  )
}

export default Card;
