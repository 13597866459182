import Form from "./components/Form";
import Paragraph from "./components/Paragraph";
import styles from "./ContactUs.module.scss";

const ContactUs = () => {
  return (
    <div id="contact" className={styles.contact}>
      <div className={styles.contactBody}>
        <Paragraph />
        <Form />
      </div>
    </div>
  );
};

export default ContactUs;
