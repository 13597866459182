import { FormEvent, useRef, useState } from "react";
import styles from "./Form.module.scss";

const Form = () => {
  const fullNameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLTextAreaElement>(null);
  const [isSent, setIsSent] = useState(false);

  //form posted by hubspot.
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (!fullNameRef.current || !emailRef.current || !messageRef.current) {
      return;
    }

    setIsSent(true);
  };

  return (
    <div className={styles.form}>
      {!isSent ? (
        <form onSubmit={handleSubmit}>
          <label>
            <p>Full name</p>
            <input
              name="full_name"
              type="text"
              ref={fullNameRef}
              required
              minLength={2}
            />
          </label>
          <label>
            <p>Email address</p>
            <input name="email" type="email" ref={emailRef} required />
          </label>
          <label>
            <p>Phone number (optional)</p>
            <input name="phone" type="tel" ref={phoneRef} />
          </label>
          <label>
            <p>Your message</p>
            <textarea
              rows={5}
              ref={messageRef}
              required
              minLength={10}
              name="message"
            />
          </label>
          <div>
            <button>CONTACT US</button>
          </div>
        </form>
      ) : (
        <div className={styles.sent}>
          <img
            src="https://storage.googleapis.com/nftstudios-website/check.png"
            alt=""
          />
          <h2>
            Thanks for <br />
            submitting the form!
          </h2>
          <p>
            Want to send another message?{" "}
            <button onClick={() => setIsSent(false)}>Click here</button>
          </p>
        </div>
      )}
    </div>
  );
};

export default Form;
