import { ReactElement } from "react";
import styles from "./SubTitle.module.scss";
import Parser from "html-react-parser";

interface ISubTitle {
  text: string;
  customClassName?: string;
}

const SubTitle = ({ text, customClassName }: ISubTitle): ReactElement => {
  const subTitleClassName = `${styles.subtitle} ${customClassName || ''}`;
  return <p className={subTitleClassName}>{Parser(text)}</p>;
};

export default SubTitle;
