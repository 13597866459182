import Title from "../../components/SectionTitle";
import styles from "./AboutUs.module.scss";
import structure_metaverse from "../../assets/images/AboutUs/structure.png";
import Paragraph, {
  ParagraphThemeTypes,
} from "../../components/Paragraph/Paragraph";

const AboutUs = () => (
  <div id="about_us" className={styles.about}>
    <Title text={"About Us"} />
    <div className={styles.aboutBody}>
      <div className={styles.aboutParagraph}>
        <Paragraph
          theme={ParagraphThemeTypes.ABOUT_PRIMARY}
          text="Voxel Architects is a Metaverse-native digital architecture studio dedicated to designing and building virtual structures and experiences."
        />

        <Paragraph
          theme={ParagraphThemeTypes.ABOUT_SECONDARY}
          text="Building in the leading virtual worlds, we help any person or brand to develop a digital presence in the cyberspace."
        />
      </div>
      <img
        src={structure_metaverse}
        height="411"
        alt="Building Metaverse Architecture"
      />
    </div>
  </div>
);

export default AboutUs;
