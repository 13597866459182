import { ReactElement } from "react";
import styles from "./CompanySign.module.scss";

interface ICompanySign {
  name: string;
  url: string;
  logo: string;
}

const CompanySign = ({ name, url, logo }: ICompanySign): ReactElement => (
  <p className={styles.companySign}>
    Powered by
    <a href={url} target="_blank" rel="noopener noreferrer" title={name}>
      <img src={logo} alt={`${name} logo`} />
    </a>
  </p>
);

export default CompanySign;
