import styles from "./ProjectsCount.module.scss";

interface IProjectsCount {
  total: number;
}

const ProjectsCount = ({ total }: IProjectsCount) => {
  return (
    <div className={styles.projectsCount}>
      <p className={styles.number}>+{total}</p>
      <p>projects</p>
    </div>
  );
};

export default ProjectsCount;
