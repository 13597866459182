import { ReactElement } from "react";
import { HashLink } from "react-router-hash-link";
import styles from "./Navbar.module.scss";
import SocialMedia from "../../../SocialMedia";
import CompanySign from "../../../CompanySign";
import { NFTSTUDIOS_URI } from "../../../../constants/config";
import nftstudios_logo from "../../../../assets/images/CompanySign/nftstudios-logo.png";

export type LinkTargetType = "_blank" | "_parent" | "_self" | "_top";
interface IHashLink {
  to: string;
  text: string;
  target?: LinkTargetType;
  pathName?: boolean;
}

interface INavbarProps {
  links: IHashLink[];
  mobile?: boolean;
}

const Navbar = ({ links, mobile }: INavbarProps): ReactElement => {
  return (
    <div className={`${mobile ? styles.navBarMobile : styles.navBar}`}>
      <ul>
        {links.map((link: IHashLink) => (
          <li key={link.to}>
            <HashLink
              key={link.to}
              smooth
              to={link?.pathName ? { pathname: link.to } : link.to}
              target={link?.target || "_self"}
              className={styles.navBarLink}
            >
              {link.text}
            </HashLink>
          </li>
        ))}
      </ul>

      <div className={styles.navBarSocial}>
        <SocialMedia navBar />

        <CompanySign
          name="NftStudios"
          url={NFTSTUDIOS_URI}
          logo={nftstudios_logo}
        />
      </div>
    </div>
  );
};

export default Navbar;
