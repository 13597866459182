import { ReactElement, ReactNode, useState } from "react";
import styles from "./EntryLoader.module.scss";

interface IEntryLoader {
  time: number;
  img: string;
  alt?: string;
  children: ReactNode;
}

const EntryLoader = ({
  time,
  img,
  alt,
  children,
}: IEntryLoader): ReactElement => {
  const [hasLoaded, setHasLoaded] = useState(false);

  const timer = setTimeout(() => {
    setHasLoaded(true);
  }, time);

  hasLoaded && clearTimeout(timer);

  return (
    <div className={styles.entryLoader}>
      <div
        className={`${styles.loader} ${hasLoaded ? styles.hide : styles.show}`}
      >
        <img src={img} alt={alt} />
      </div>

      <div>{children}</div>
    </div>
  );
};

export default EntryLoader;
