import { LinkTargetType } from "../components/Header/components/Navbar/Navbar";
import { URL_PORTFOLIO } from "./externalLinks";

export const HOME = "/";
const SERVICES = "/#services";
const ABOUT_US = "/#about_us";
const METAVERSE = "/#metaverse";
const PARTNERS = "/#partners";
export const CONTACT = "/#contact";
const PORTFOLIO = URL_PORTFOLIO ?? HOME;

export const HOME_NAVBAR_LINKS = [
  { to: SERVICES, text: "Services" },
  { to: ABOUT_US, text: "About us" },
  { to: METAVERSE, text: "Metaverses" },
  { to: PARTNERS, text: "Partners & Clients" },
  {
    to: PORTFOLIO,
    pathName: true,
    text: "Portfolio",
    target: "_blank" as LinkTargetType,
  },
  { to: CONTACT, text: "Contact" },
];
