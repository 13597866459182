import Card from "../../components/Card";
import Title from "../../components/SectionTitle";
import { servicesData } from "../../constants/services";
import styles from "./Services.module.scss";

const Services = () => {
  return (
    <div id="services" className={styles.container}>
      <Title whitecolor text={"Services"} />
      <section className={styles.cardsContainer}>
        {servicesData.map((service) => (
          <Card
            key={service.id}
            title={service.title}
            textContent={service.description}
          />
        ))}
      </section>
    </div>
  );
};

export default Services;
