import { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "./components/Navbar";
import { HOME, HOME_NAVBAR_LINKS } from "../../constants/routes";
import website_logo from "../../assets/images/header/logo_website.png";
import close_icon from "../../assets/images/header/close_icon.png";
import hamb_icon from "../../assets/images/header/ham_icon.png";
import styles from "./Header.module.scss";

const Header = (): ReactElement => {
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    if (toggle) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "visible";
    }
  }, [toggle]);

  return (
    <>
      <div id="top" className={styles.container}>
        <div className={styles.header}>
          <Link to={HOME}>
            <img
              src={website_logo}
              className={styles.headerImage}
              alt="Voxel Architects logo"
            />
          </Link>
          <div>
            <Navbar links={HOME_NAVBAR_LINKS} />
          </div>
        </div>
      </div>

      <div
        id="top"
        onClick={() => setToggle(!toggle)}
        className={styles.containerMobile}
      >
        <div className={styles.headerMobile}>
          <Link to={HOME}>
            <img src={website_logo} height="75" alt="Voxel Architects logo" />
          </Link>

          <button type="submit" className={styles.menuIcon}>
            {toggle ? (
              <img src={close_icon} alt="close menu" />
            ) : (
              <img src={hamb_icon} alt="open menu" />
            )}
          </button>
        </div>
        {toggle && <Navbar mobile links={HOME_NAVBAR_LINKS} />}
      </div>
    </>
  );
};

export default Header;
