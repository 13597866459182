import { dataMetaverses } from "./MetaverseTypes.data";
import styles from "./MetaverseTypes.module.scss";
import BoxImage from "../../../../components/BoxImage";

const MetaverseTypes = () => {
  return (
    <div className={styles.metaverseTypes}>
      {dataMetaverses.map((metaverse) => {
        return (
          <BoxImage
            key={metaverse.id}
            id={metaverse.id}
            name={metaverse.name}
            imageUrl={metaverse.logo}
            customClass={styles.metaverselogos}
            width={metaverse.width ?? "100%"}
          />
        );
      })}
    </div>
  );
};

export default MetaverseTypes;
