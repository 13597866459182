import { ReactElement } from "react";
import styles from "./SectionTitle.module.scss";

interface ISectionTitle {
  text: string;
  whitecolor?: boolean;
}

const SectionTitle = ({ text, whitecolor }: ISectionTitle): ReactElement => (
  <div className={`${styles.container} ${whitecolor && styles.whiteColor}`}>
    <h2>{text}</h2>
  </div>
);

export default SectionTitle;
