import { ReactElement } from "react";
import Home from "../../pages/Home";
import Services from "../../pages/Services";
import AboutUs from "../../pages/AboutUs";
import Metaverses from "../../pages/Metaverses";
import Partners from "../../pages/Partners";
import ContactUs from "../../pages/ContactUs";
import styles from "./Main.module.scss";
import Footer from "../../components/Footer";

const Main = (): ReactElement => (
  <div className={styles.container}>
    <Home />
    <Services />
    <AboutUs />
    <Metaverses />
    <Partners />
    <ContactUs />
    <Footer />
  </div>
);

export default Main;
