import { ReactElement } from "react";
import { URL_PORTFOLIO } from "../../constants/externalLinks";
import { CONTACT } from "../../constants/routes";
import ButtonLink from "../Button";
import { ButtonThemeTypes } from "../Button/Button";
import ButtonHash from "../ButtonHash";
import styles from "./BoxButton.module.scss";

const BoxButton = (): ReactElement => (
  <div className={styles.buttonsContainer}>
    <ButtonLink
      text="Portfolio"
      to={URL_PORTFOLIO}
      theme={ButtonThemeTypes.BORDERED_WHITE}
      customClass={styles.buttonMargin}
    />
    <ButtonHash
      text="Book a project"
      to={CONTACT}
      theme={ButtonThemeTypes.BORDERED_WHITE}
    />
  </div>
);

export default BoxButton;
